import * as Sentry from "@sentry/remix";
/**
 * By default, Remix will handle hydrating your app on the client for you.
 * You are free to delete this file if you'd like to, but if you ever want it revealed again, you can run `npx remix reveal` ✨
 * For more information, see https://remix.run/file-conventions/entry.client
 */

import { CookiesProvider } from "react-cookie";
import { RemixBrowser, useLocation, useMatches } from "@remix-run/react";
import { startTransition, StrictMode, useEffect } from "react";
import { hydrateRoot } from "react-dom/client";
import { PostHogProvider } from "posthog-js/react";

Sentry.init({
  dsn: "https://cb040b53c2e948c618de7a565479b46a@o4507995790311424.ingest.us.sentry.io/4508091689730048",
  tracesSampleRate: 1,

  integrations: [
    Sentry.browserTracingIntegration({
      useEffect,
      useLocation,
      useMatches,
    }),
    Sentry.replayIntegration({
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],

  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1,
});

startTransition(() => {
  hydrateRoot(
    document,
    <CookiesProvider>
      <PostHogProvider
        apiKey="phc_pTdmeibMSJQ1jEc7AjIrd9RMqa11skJ90GdcLSHwmHr"
        options={{
          api_host: "https://eu.i.posthog.com",
          person_profiles: "identified_only",
        }}
      />
      <StrictMode>
        <RemixBrowser />
      </StrictMode>
    </CookiesProvider>,
  );
});
